import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}
  getAllCoachs(): Observable<any[]> {
    return this.http.get(environment.apiURL + `/user/coachs`) as Observable<
      any[]
    >;
  }

  getUsers(): Observable<any[]> {
    return this.http.get(environment.apiURL + `/user`) as Observable<any[]>;
  }
  createUser(user: any): Observable<any> {
    return this.http.post(
      environment.apiURL + `/user`,
      user
    ) as Observable<any>;
  }
  updateUser(user: any): Observable<any> {
    return this.http.post(
      environment.apiURL + `/user/update`,
      user
    ) as Observable<any>;
  }
  deleteUser(user: any): Observable<any> {
    return this.http.post(
      environment.apiURL + `/user/delete`,
      user
    ) as Observable<any>;
  }
  updateUserAdmin(user: any): Observable<any> {
    console.log('admin');
    return this.http.post(
      environment.apiURL + `/user/update/admin`,
      user
    ) as Observable<any>;
  }
  updateUserAttr(attr: any, value: any, id: any): Observable<any> {
    console.log(attr, value, id);
    return this.http.post(environment.apiURL + `/user/update/attr`, {
      attr,
      value,
      id,
    }) as Observable<any>;
  }
  updateUserPass(user: any): Observable<any> {
    return this.http.post(
      environment.apiURL + `/user/password/update`,
      user
    ) as Observable<any>;
  }
  getUser(idUser: any): Observable<any> {
    return this.http.get(
      environment.apiURL + `/user/` + idUser
    ) as Observable<any>;
  }
  succeededPayment(data: any): Observable<any> {
    return this.http.post(
      environment.apiURL + `/user/succeededPayement`,
      data
    ) as Observable<any>;
  }
  succeededOffMarket(data: any): Observable<any> {
    return this.http.post(
      environment.apiURL + `/user/succeededOffMarket`,
      data
    ) as Observable<any>;
  }
}
